$().ready(function(){
    already_in_view();
    if ($('form').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active').validate({
                rules: {
                    "services_requested": {
                        require_from_group: [1, '.services-requested']
                    }
                }
            });
            $(this).find('.js-float-wrap').FloatLabel();
        });
    }
    pageLogo();
    $('button.openForm').click(function() {
        $('body').addClass('noScroll');
    });
    $('.close').click(function() {
        $(this).closest('div.pop-up').css('display', 'none');
        $('body').removeClass('noScroll');
        $('form').each(function(){
            $('form').trigger('reset');
        });
    });
    if ($('#alerts p.alert-info')){
        logoTopPosition();
    }
});

var alert = $('#alerts p.alert-info');
var logo = $('h1.logo');

function logoTopPosition() {
    logo.css('top', '8vw');
}

function pageLogo() {
    var bannerHeight = $('.banners-home').outerHeight();
    $(document).scroll(function(){
        if ($(window).scrollTop() > (bannerHeight / 2)){
            $("header h1.logo").addClass('fixed');
            logo.css('top', '4vw');
        } else {
            $("header h1.logo").removeClass('fixed');
            if (alert) {
                logoTopPosition();
            }
        }
    });
}

var $animation_elements = $('.animation-element, .page-content h1, .page-content h2, .page-content h3, .page-content p, [data-edit] div, [data-edit] li, footer .banner');
var $window = $(window);

function already_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is already within viewport
        if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
            $element.addClass('already-in-view');
        }
    });
}

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
            $element.addClass('in-view');
        }

        //remove class when scrolled back to top
        if (window_top_position == 0) {
            $element.removeClass('in-view');
        }
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');